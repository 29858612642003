<script>
export default {
  name: 'IndexToken',
};
</script>

<template>
  <router-view/>
</template>

<style lang="stylus">

</style>
